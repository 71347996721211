/*  imported from formBuilder.scss  */

.formBuilder__reactFormBuilder___o_kxH {
  padding: 15px; }

.formBuilder__reactFormBuilder___o_kxH input[type=checkbox],
.formBuilder__reactFormBuilder___o_kxH input[type=radio] {
  margin-right: 5px; }

.formBuilder__reactFormBuilderPreview___23XDF {
  position: relative;
  width: calc(100% - 270px);
  border: 1px solid #ddd;
  background: #fafafa;
  padding: 10px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  min-height: 200px; }

.formBuilder__reactFormBuilderPreview___23XDF label {
  font-weight: normal; }

.formBuilder__reactFormBuilderPreview___23XDF .form-label {
  display: block !important; }

.formBuilder__reactFormBuilderPreviewBtn___5zVZr {
  padding-bottom: 20px;
  padding-right: 270px; }

.formBuilder__droppableContainer___MUGU- > div {
  width: 100%;
  min-height: 200px; }

.formBuilder__droppableContainer___MUGU- > div > li {
  list-style-type: none; }

.formBuilder__reactFormBuilderPreviewModal___3MaJN .ant-modal-body {
  padding: 0; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-disabled + span,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.65) !important;
  cursor: default !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-disabled input,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-disabled input {
  cursor: default !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-disabled .ant-checkbox-inner,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-disabled .ant-radio-inner {
  background: #fff; }

.formBuilder__experiumPlayerBuilder___gMtxw .DraftEditor-root {
  cursor: initial; }

.formBuilder__experiumPlayerBuilder___gMtxw .public-DraftEditorPlaceholder-inner {
  white-space: nowrap !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-group,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-group {
  line-height: 1.5 !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-group .ant-checkbox-wrapper,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-group .ant-radio-wrapper {
  display: inline-block;
  margin-left: 0 !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-group .ant-checkbox-wrapper p,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-group .ant-radio-wrapper p {
  display: inline-block;
  margin-bottom: 0 !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-form-item-label {
  width: 100%;
  overflow: visible !important;
  text-align: left !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-form-item-label p {
  margin-bottom: 0;
  display: inline; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-form-item-control.has-error .ant-radio-inner,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-form-item-control.has-error .ant-checkbox-inner {
  border-color: #f5222d; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-slider-mark .ant-slider-mark-text:first-child {
  width: 50% !important;
  margin-left: -6px !important;
  text-align: left; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-slider-mark .ant-slider-mark-text:last-child {
  width: 50% !important;
  right: 0;
  left: auto !important;
  margin-left: 0 !important;
  margin-right: -6px;
  text-align: right; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-slider-mark .ant-slider-mark-text:last-child .public-DraftEditorPlaceholder-root {
  right: 0; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-slider-disabled {
  cursor: default !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-slider-disabled .ant-slider-mark-text,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-slider-disabled .ant-slider-step .ant-slider-dot,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-slider-disabled .ant-slider-handle {
  cursor: default !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-slider-disabled .ant-slider:hover .ant-slider-rail {
  background: #f5f5f5; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-form-item {
  margin-bottom: 10px !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .public-DraftStyleDefault-block,
.formBuilder__experiumPlayerBuilder___gMtxw .public-DraftEditorPlaceholder-inner,
.formBuilder__experiumPlayerBuilder___gMtxw .public-DraftStyleDefault-orderedListItem,
.formBuilder__experiumPlayerBuilder___gMtxw .public-DraftStyleDefault-unorderedListItem {
  line-height: normal !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .alignment--left .public-DraftStyleDefault-block {
  text-align: left; }

.formBuilder__experiumPlayerBuilder___gMtxw .alignment--center .public-DraftStyleDefault-block {
  text-align: center; }

.formBuilder__experiumPlayerBuilder___gMtxw .alignment--right .public-DraftStyleDefault-block {
  text-align: right; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-wrapper.required {
  color: #f5222d; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-wrapper.required:after {
  margin: 0 !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-wrapper.correct .ant-checkbox,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-wrapper.incorrect .ant-checkbox,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-wrapper.correct .ant-radio,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-wrapper.incorrect .ant-radio {
  cursor: default !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-wrapper.correct .ant-checkbox-inner,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-wrapper.incorrect .ant-checkbox-inner,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-wrapper.correct .ant-radio-inner,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-wrapper.incorrect .ant-radio-inner {
  border: none;
  position: relative; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-wrapper.correct .ant-checkbox-inner:before,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-wrapper.incorrect .ant-checkbox-inner:before,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-wrapper.correct .ant-radio-inner:before,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-wrapper.incorrect .ant-radio-inner:before {
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  left: 2px; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-wrapper.correct .ant-checkbox-inner:after,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-wrapper.incorrect .ant-checkbox-inner:after,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-wrapper.correct .ant-radio-inner:after,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-wrapper.incorrect .ant-radio-inner:after {
  opacity: 0; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-wrapper.correct .ant-checkbox-inner:before,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-wrapper.correct .ant-radio-inner:before {
  content: '\F00C';
  color: #41b567; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-checkbox-wrapper.incorrect .ant-checkbox-inner:before,
.formBuilder__experiumPlayerBuilder___gMtxw .ant-radio-wrapper.incorrect .ant-radio-inner:before {
  content: '\F00D';
  color: #f5222d; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-form-item-has-error .option-item-correct .ant-checkbox .ant-checkbox-inner,
.formBuilder__experiumPlayerBuilder___gMtxw .option-item-correct .ant-checkbox-wrapper-disabled .ant-checkbox .ant-checkbox-inner,
.formBuilder__experiumPlayerBuilder___gMtxw .option-item-correct .ant-radio-wrapper-disabled .ant-checkbox .ant-checkbox-inner {
  border-color: #A6B622 !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .ant-form-item-has-error .option-item-correct .ant-radio .ant-radio-inner,
.formBuilder__experiumPlayerBuilder___gMtxw .option-item-correct .ant-checkbox-wrapper-disabled .ant-radio .ant-radio-inner,
.formBuilder__experiumPlayerBuilder___gMtxw .option-item-correct .ant-radio-wrapper-disabled .ant-radio .ant-radio-inner {
  border-color: #A6B622 !important; }

.formBuilder__experiumPlayerBuilder___gMtxw .edit-element.Editor .edit-element-fields {
  padding: 0; }
  .formBuilder__experiumPlayerBuilder___gMtxw .edit-element.Editor .edit-element-fields .tox-tinymce {
    border: none;
    box-shadow: none; }
/*  imported from editor.scss  */

.editor__editor___1oMVM {
  display: flex;
  flex-direction: row; }

.editor__editorCol___1rlEk {
  width: 50%;
  border-right: 1px solid #e8e8e8; }
  .editor__editorCol___1rlEk .ant-form {
    height: 100%; }

.editor__editorPreviewCol___3Lgw0 {
  width: 50%;
  padding: 15px;
  max-height: calc(100vh - 154px);
  overflow-y: auto; }

.editor__editorCol___1rlEk p,
.editor__editorPreviewCol___3Lgw0 p {
  margin-bottom: 0 !important; }

.editor__editorField___1_Zq6 {
  padding: 10px;
  border: 1px solid #e8e8e8; }

.editor__editorModal___2hd0A {
  top: 50px !important; }
  .editor__editorModal___2hd0A .ant-modal-body {
    max-height: calc(100vh - 154px);
    overflow: hidden;
    padding: 0; }

.editor__editorFooter___3Mszd {
  border-top: 1px solid #e8e8e8;
  padding: 15px;
  width: 100%;
  background: #fff; }

.editor__editorFields___csq02 {
  padding: 15px;
  max-height: calc(100vh - 216px);
  overflow-y: auto;
  height: calc(100% - 62px); }

.editor__editorColHidePreview___8DImo {
  width: 100%;
  border: none; }

.editor__editor-view___1wDKx {
  overflow: hidden; }
/*  imported from toolbar.scss  */

.toolbar__draggableToolbarItemWrapper___1Us4d {
  padding: 5px;
  list-style: none; }

.toolbar__draggingToolbarItemWrapper___1BVC1 {
  transform: none !important; }

.toolbar__draggableToolbarItem___Sj7FY {
  padding: 10px;
  border: 1px dashed #ddd;
  background: #fff;
  position: relative; }

.toolbar__draggableToolbarItem___Sj7FY i {
  margin-right: 10px; }

.toolbar__toolbar___1Qa5r {
  float: right;
  width: 250px;
  background: #fff;
  margin-top: -28px; }

.toolbar__toolbar___1Qa5r h4 {
  margin-top: 0;
  text-align: center; }

.toolbar__toolbar___1Qa5r ul {
  padding: 0; }
/*  imported from colorPicker.scss  */

.colorPicker__swatch___2lU61 {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  margin-left: 1px;
  margin-top: 4px; }

.colorPicker__color___1g29P {
  width: 36px;
  height: 22px;
  border-radius: 2px; }

.colorPicker__cover___Nq9mR {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999; }

.colorPicker__popover___1hUcI {
  position: absolute;
  z-index: 1000;
  top: 4px;
  left: 48px; }
  .colorPicker__popover___1hUcI .chrome-picker {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px, rgba(0, 0, 0, 0.1) 0px 4px 8px !important; }
/*  imported from imageUploader.scss  */

.imageUploader__image___3Ty7X {
  background-position: center;
  width: 15px;
  height: 15px;
  background-size: cover;
  margin-right: 10px; }

.imageUploader__imageInfo___23LyR {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  bottom: -30px; }

.imageUploader__imageRemoveBtn___29f5L {
  margin-left: 15px;
  cursor: pointer;
  transition: all .3s; }
  .imageUploader__imageRemoveBtn___29f5L:hover {
    color: #000; }
/*  imported from editorInput.scss  */

.editorInput__editorInput___1WcYS {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 7px 10px;
  min-height: 80px; }
  .editorInput__editorInput___1WcYS .mce-content-body {
    min-height: 70px; }
    .editorInput__editorInput___1WcYS .mce-content-body * {
      line-height: 20px; }
  .editorInput__editorInput___1WcYS .mce-edit-focus {
    outline: none; }

.editorInput__editorInputShort___3k_dC {
  min-height: auto; }
  .editorInput__editorInputShort___3k_dC .mce-content-body {
    min-height: auto; }
/*  imported from multipleField.scss  */

.multipleField__item___1fVdw {
  display: flex;
  padding-bottom: 10px;
  background: #fff; }
  .multipleField__item___1fVdw .ant-row.ant-form-item {
    width: 100%;
    margin-bottom: 0; }
  .multipleField__item___1fVdw .ant-btn.ant-btn-icon-only {
    height: 39px; }
  .multipleField__item___1fVdw .ant-btn-danger {
    width: 40px; }
  .multipleField__item___1fVdw .ant-btn {
    border: none;
    box-shadow: none; }
  .multipleField__item___1fVdw .ant-upload-select {
    margin-left: 5px; }
  .multipleField__item___1fVdw .ant-form-item-label {
    display: none; }

.multipleField__itemHasImage___35LM3 {
  padding-bottom: 32px; }

.multipleField__itemContent___2EMMX {
  display: flex;
  position: relative;
  width: 100%; }

.multipleField__itemDescription___3tc2F {
  display: flex;
  position: relative;
  width: 100%;
  flex: 1; }
  .multipleField__itemDescription___3tc2F .ant-row {
    width: 100%;
    padding-bottom: 10px; }

.multipleField__dragHandler___1GPvk {
  display: flex;
  align-items: center;
  padding: 0 10px; }
/*  imported from options.scss  */

.options__optionItem___3iJsc:hover .options__optionRemoveBtn___1nlIt {
  display: inline-block; }

.options__optionReorder___1eSk7 {
  display: inline-block;
  padding: 5px 10px 5px 5px;
  cursor: grab; }

.options__optionAddBtn___3eI-a,
.options__optionRemoveBtn___1nlIt {
  border: none !important;
  font-weight: 700 !important;
  float: right;
  line-height: 60px; }

.options__optionRemoveBtn___1nlIt {
  display: none; }

.options__optionLabel___1yWrm p {
  display: inline; }

.options__correctAnswers___18tyV p {
  display: inline-block;
  margin-bottom: 0 !important; }

.options__correctAnswers___18tyV em {
  font-style: normal !important; }

.options__correctAnswers___18tyV u {
  text-decoration: none !important; }

.options__correctAnswers___18tyV strong {
  font-weight: normal !important; }

.options__image___3zj7j {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px; }

.options__contentWithImage___126j8 {
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 10px; }

.options__inlineOptions___1A8eP div[data-react-beautiful-dnd-draggable] {
  display: inline-flex;
  margin-right: 10px;
  vertical-align: bottom; }
/*  imported from editorToolbar.scss  */

.editorToolbar__toolbar___r4vlg {
  position: absolute;
  z-index: 9999;
  height: 35px;
  cursor: default;
  visibility: hidden; }

.editorToolbar__showToolbar___2Ygda {
  visibility: visible; }

.editorToolbar__showToolbar___2Ygda .editorToolbar__toolbarItems___2zvDc,
.editorToolbar__showToolbar___2Ygda .editorToolbar__toolbarItem___1D-K3 {
  opacity: 1; }

.editorToolbar__toolbarItems___2zvDc {
  opacity: 0;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  height: 100%;
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom, #f5f5f5 0%, #ededed 100%);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 25px -4px rgba(0, 0, 0, 0.25); }

.editorToolbar__toolbarItemsArrow___2PwFu {
  position: absolute;
  bottom: -5px;
  margin-left: -5px;
  border-top: 5px solid #444;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent; }

.editorToolbar__toolbarItem___1D-K3 {
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 35px;
  max-width: 35px;
  color: #444;
  border-bottom: 3px solid #444;
  transition: all 0.15s linear;
  cursor: pointer; }

.editorToolbar__toolbarItem___1D-K3:hover svg path {
  fill: #1890ff; }

.editorToolbar__toolbarItem___1D-K3:hover,
.editorToolbar__active___24dHq {
  color: #1890ff;
  border-color: #1890ff; }

.editorToolbar__bold___1I-VY {
  font-weight: bold; }

.editorToolbar__italic___1cQ9v {
  font-style: italic; }

.editorToolbar__underline___xoLQR {
  text-decoration: underline; }

.editorToolbar__toolbarLinkContainer___14xpk {
  color: #444;
  border-bottom: 3px solid #444;
  display: flex;
  padding: 3px; }

.editorToolbar__toolbarLinkContainer___14xpk:after {
  content: '';
  position: absolute;
  bottom: -5px;
  margin-left: -5px;
  border-top: 5px solid #444;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent; }

.editorToolbar__toolbarLinkContainer___14xpk input {
  border: none;
  background: none;
  border-bottom: 1px solid #ccc; }

.editorToolbar__toolbarLinkContainer___14xpk input:focus {
  outline: none; }

.editorToolbar__toolbarLinkContainer___14xpk button {
  background: none;
  border: none;
  transition: color .3s;
  cursor: pointer; }

.editorToolbar__toolbarLinkContainer___14xpk button:hover {
  color: #1890ff; }

.editorToolbar__toolbarLinkContainer___14xpk button:focus {
  outline: none; }
/*  imported from pdf.scss  */

.pdf__pdfFooter___1Q-cn {
  text-align: center;
  margin-top: 15px; }

.pdf__pdfPageButtons___1VVGa {
  text-align: center;
  margin-bottom: 15px; }

.pdf__pdf___3UETx .react-pdf__Page {
  text-align: center; }

.pdf__pdf___3UETx .react-pdf__Page__canvas {
  display: inline-block !important; }

.pdf__pdfSpin___3XpG2 {
  text-align: center; }

.pdf__pdfFullView___1m62k {
  top: 0 !important;
  padding-bottom: 0 !important; }
  .pdf__pdfFullView___1m62k .ant-modal-content {
    height: 100vh;
    border-radius: 0; }
  .pdf__pdfFullView___1m62k .ant-modal-body {
    padding: 0;
    height: calc(100% - 53px); }
/*  imported from range.scss  */

.range__rangeSettings___2Or0X {
  position: relative; }

.range__maxValueInput___K7Cw9 {
  float: right; }

.range__minValueInput___2xpVD {
  float: left; }

.range__stepInput___3QSRm {
  position: absolute;
  left: calc(50% - 66px);
  top: -4px; }
/*  imported from video.scss  */

.video__video___2vGQt input {
  margin-top: 5px; }

.video__videoWrapper___1Sou1 {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  margin-top: 10px; }

.video__videoWrapper___1Sou1.video__dragging-over___1fN0t:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }
/*  imported from sortableRow.scss  */

.sortableRow__sortableRowWrapper___1L9Qx {
  padding-bottom: 10px; }

.sortableRow__sortableRow___1Pn_L {
  border: 1px dashed #ddd;
  background: #fafafa;
  display: inline-block;
  width: 100%;
  position: relative;
  display: table; }
  .sortableRow__sortableRow___1Pn_L .ant-form-item-control .ant-checkbox-group,
  .sortableRow__sortableRow___1Pn_L .ant-form-item-control .ant-radio-group {
    width: 100%; }
  .sortableRow__sortableRow___1Pn_L .ant-form-item-control .ant-radio-group .ant-radio-wrapper {
    margin-right: 0 !important; }
  .sortableRow__sortableRow___1Pn_L .ant-form-item-control .ant-checkbox-group .ant-checkbox + span,
  .sortableRow__sortableRow___1Pn_L .ant-form-item-control .ant-radio-group .ant-radio + span {
    padding-left: 0 !important; }

.sortableRow__sortableRowContent___12EAS {
  display: table-cell;
  width: calc(100% - 50px);
  padding: 15px 15px 15px 0; }

.sortableRow__sortableRowDragHandle___HBS35 {
  display: table-cell;
  width: 40px;
  text-align: center;
  font-size: 15px;
  vertical-align: middle; }

.sortableRow__sortableRow___1Pn_L:hover .sortableRow__toolbarRemoveBtn___QAYsb,
.sortableRow__sortableRow___1Pn_L:hover .sortableRow__toolbarEditBtn___2hxSs {
  opacity: 1; }

.sortableRow__toolbarEditBtn___2hxSs,
.sortableRow__toolbarRemoveBtn___QAYsb {
  position: absolute;
  opacity: 0;
  top: -15px;
  right: -15px;
  height: 30px;
  width: 30px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  z-index: 1; }

.sortableRow__toolbarEditBtn___2hxSs:focus,
.sortableRow__toolbarRemoveBtn___QAYsb:focus {
  outline: none; }

.sortableRow__toolbarEditBtn___2hxSs {
  right: 20px; }

.sortableRow__sortableRowInfo___w8I0I {
  max-height: 23px;
  overflow: hidden; }
  .sortableRow__sortableRowInfo___w8I0I * {
    margin: 0;
    padding: 0;
    display: inline;
    font-size: 14px !important;
    font-family: inherit !important;
    color: rgba(0, 0, 0, 0.65) !important;
    background-color: transparent !important;
    text-decoration: none !important;
    font-weight: 400 !important;
    font-style: normal !important;
    padding: 0 !important;
    pointer-events: none; }
  .sortableRow__sortableRowInfo___w8I0I img, .sortableRow__sortableRowInfo___w8I0I table, .sortableRow__sortableRowInfo___w8I0I br {
    display: none !important; }

.sortableRow__sortableRowContentInfo___LXp6p {
  color: #aaa; }
  .sortableRow__sortableRowContentInfo___LXp6p i {
    margin-right: 8px; }

.sortableRow__elementEditor___11s4M .ant-radio-wrapper, .sortableRow__elementEditor___11s4M .ant-checkbox-wrapper {
  float: left;
  line-height: 60px; }

.sortableRow__elementEditor___11s4M .sortable-row-wrapper {
  margin-left: 30px;
  margin-right: 50px; }
  .sortableRow__elementEditor___11s4M .sortable-row-wrapper .sortable-row-content {
    padding-left: 15px; }

.sortableRow__elementEditor___11s4M .option-item {
  clear: both; }
  .sortableRow__elementEditor___11s4M .option-item .option-reorder {
    display: none; }
